/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"

import Button from "components/Button"
import Popover from "components/Popover"
import { t as globalT } from "helpers/i18n"
import * as Shift from "timesheets/models/shift"

import BreaksPopoverRow from "./BreaksPopoverRow"
import styles from "./styles.module.scss"

const t = (key) => globalT(`js.timesheets.shift_card.breaks_popover.${key}`)

export default class BreaksPopover extends React.PureComponent {
  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickToExit)
  }

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickToExit)
  }

  handleAddButton = () => {
    this.props.actions.shiftsBreakCreate({ shift: this.props.shift })
  }

  handleClickToExit = (e) => {
    // if the popover is visible, and you click outside it, dismiss it
    // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-react-component-5604830beb7f
    if (!this.props.visible || this.node.contains(e.target)) {
      return
    }

    this.props.hidePopover()
  }

  setNode = (node) => {
    this.node = node
  }

  render() {
    return (
      <span ref={this.setNode}>
        <Popover hovered={this.props.visible} offset={0.75} title={t("title")}>
          <div className={styles.breaksPopover}>
            {this.props.shift.get(Shift.BreaksFieldName).map((sb, index) => (
              <BreaksPopoverRow
                actions={this.props.actions}
                canCreatePaidMealBreak={this.props.canCreatePaidMealBreak}
                currentUser={this.props.currentUser}
                disabled={this.props.disabled}
                isFirstBreak={index === 0}
                key={sb.get("guid")}
                shift={this.props.shift}
                shiftBreak={sb}
              />
            ))}

            {!this.props.disabled ? (
              <Button iconLeft="add" label={t("add")} onClick={this.handleAddButton} size="sm" type="theme" />
            ) : null}
          </div>
        </Popover>
      </span>
    )
  }
}

BreaksPopover.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  canCreatePaidMealBreak: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  shift: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  hidePopover: PropTypes.func.isRequired,
}
