// @flow
import moment from "moment"
import * as Model from "platform/models/model"
import type { Defaults as LeaveRequestDefaults } from "time_off/Modal/types"
import type { Position, PositionGroup } from "positions/types"
import type { RuleSetType } from "../types"
import * as Loading from "./loading"
import * as Config from "./config"
import * as Cognitive from "./cognitive/types"
import * as Schedule from "./schedule/types"
import * as PublishedSchedule from "./publishedSchedule/types"
import * as Shift from "./shift/types"
import * as DemandData from "./demandData/types"
import * as Focus from "./focus"
import * as Cache from "./cache"
import * as Rosters from "./rosters"
import * as Static from "./static"
import * as TeamGroup from "./static/team_group"
import * as Location from "./static/location"
import * as Team from "./static/team"
import * as ShiftDetail from "./static/shiftDetail"
import * as User from "./static/user/types"
import * as TimeOff from "./timeOff"
import * as Modal from "./modal"
import * as AIC from "./aic/types"
import * as Settings from "./settings"
import * as RDO from "./rdo/types"
import * as Validation from "./validation"
import * as ViewOptions from "./viewOptions"
import * as CustomEvent from "./customEvent/types"
import * as Comment from "./comment/types"
import * as DataStream from "./dataStream"
import * as Weather from "./weather"
import * as Qualification from "./static/qualifications"
import * as UserQualification from "./static/userQualifications"
import * as DepartmentQualification from "./static/departmentQualifications"
import * as TrainingLogs from "./static/trainingLogs"
import * as History from "./history"
import * as StatType from "./statType"
import * as Template from "./template"
import * as TemplateUserJoins from "./templateUserJoins"
import * as DataStreamJoin from "./dataStream/dataStreamJoin"
import * as HeadCountMap from "./dataStream/headCountMap"
import * as RosterPatterns from "./rosterPatterns/types"
import * as SalesTarget from "./salesTarget"
import * as ScheduleSwapPlans from "./scheduleSwapPlan"
import * as OncostConfigurations from "./oncostConfiguration"
import * as EmploymentConditionSet from "./employmentConditionSet"

export type GlobalState = $ReadOnly<{|
  cache: Cache.Schema,
  cognitive: Cognitive.Schema,
  comments: Array<Comment.Schema>,
  config: Config.Schema,
  contractRDOs: Array<RDO.Schema>,
  custom_events: Array<CustomEvent.Schema>,
  data_stream_joins: Array<DataStreamJoin.Schema>,
  data_streams: Array<DataStream.Schema>,
  demand_data: DemandData.Schema,
  display_message: {|
    text: string,
  |},
  employment_condition_sets: Array<EmploymentConditionSet.Schema>,
  enable_automatic_breaks: boolean,
  focus: Focus.Schema,
  head_count_maps: Array<HeadCountMap.Schema>,
  history: History.Schema,
  loading: Loading.Schema,
  modal: Modal.Schema,
  oncost_configurations: Array<OncostConfigurations.Schema>,
  published_schedules: Array<PublishedSchedule.Schema>,
  rdos: Array<RDO.Schema>,
  roster_patterns: RosterPatterns.Schema,
  rosters: Rosters.Schema,
  rule_sets: Array<RuleSetType>,
  sales_targets: Array<SalesTarget.Schema>,
  schedule_aics: Array<AIC.Schema>,
  schedule_swap_plans: Array<ScheduleSwapPlans.Schema>,
  schedules: Array<Schedule.Schema>,
  settings: Settings.Schema,
  shifts: Array<Shift.Schema>,
  staged: $ReadOnly<{|
    staged_comment: ?Comment.Schema,
    staged_custom_event: ?CustomEvent.Schema,
    staged_date: ?string,
    staged_leave: LeaveRequestDefaults,
    staged_schedule: ?Schedule.Schema,
    staged_schedules: Array<Schedule.Schema>,
    staged_template_id: ?number,
    staged_user_ids: Array<number>,
  |}>,
  stat_types: Array<StatType.Schema>,
  static: Static.Schema,
  template_user_joins: Array<TemplateUserJoins.Schema>,
  templates: Array<Template.Schema>,
  time_off: TimeOff.Schema,
  validations: Validation.Schema,
  view_options: ViewOptions.Schema,
  weather: Array<Weather.Schema>,
|}>

export const DEFAULT_STATE: GlobalState = {
  enable_automatic_breaks: true,
  rule_sets: [],
  cache: {
    predicted_demand_data: {},
    actual_demand_data: {},
    schedule_user_data: {},
    user_data: {},
    schedule_team_data: {},
    location_data: {},
    shift_user_data: {},
    shift_team_data: {},
    weather_data: {},
  },
  display_message: { text: "" },
  data_stream_joins: [],
  head_count_maps: [],
  data_streams: [],
  validations: {
    pay_period_validation_errors: {},
    schedule_validation_errors: {},
    roster_validation_errors: {},
  },
  demand_data: {
    actual: [],
    predicted: [],
  },
  stat_types: [],
  custom_events: [],
  comments: [],
  static: {
    archived_teams: [],
    teams: [],
    team_groups: [],
    users: [],
    locations: [],
    departments: [],
    shift_details: [],
    qualifications: [],
    user_qualifications: [],
    position_groups: [],
    positions: [],
    department_qualifications: [],
    training_logs: [],
  },
  focus: {
    selected_schedule_container_data: null,
    selected_person_data: null,
    selected_date_data: null,
  },
  cognitive: {
    demand_config: [],
    cross_department_proficiencys: [],
    cognitive_creator_configurations: [],
    prediction_modifiers: [],
  },
  modal: {
    bulk_edit_modal_open: false,
    auto_assign_modal_open: false,
    auto_build_success_modal_open: false,
    template_modal_open: false,
    copy_schedules_modal_open: false,
    predict_modal_open: false,
    publish_modal_open: false,
    leave_modal_open: false,
    replacement_modal_open: false,
    custom_event_modal_open: false,
    recost_roster_modal_open: false,
    comment_modal_open: false,
    roster_pattern_build_modal_open: false,
    roster_pattern_modal_open: false,
  },
  config: {
    shift_slots: [],
    awards: [],
    allowances: [],
    can_create_paid_meal_breaks: false,
    current_user_id: -1,
    show_weekends: true,
    sms_enabled: true,
    enable_secondary_departments: false,
    enable_full_location_rosters: false,
    enable_staff_type_filter: false,
    public_holidays: { all: [] },
    business_hours: [],
    organisation: {
      country: "Australia",
      enable_predictive_headcounts: false,
      forecasting_strategy: "average_of_dates",
      id: -1,
      one_click_schedule: false,
    },
    pay_period_length: 7,
    roster_week_start_day: 1,
    permissions: {
      can_create_leave: false,
      can_approve_leave: false,
      can_approve_own_leave: false,
      can_update_leave: false,
      can_create_timesheets: false,
      can_create_schedule: false,
      can_update_schedule: false,
      can_lock_rosters: false,
      can_publish_rosters: false,
      can_import_ratios: false,
      can_update_shift_proposal: false,
      can_update_open_hour_periods: false,
      can_update_projections_table_configuration: false,
      can_view_projections_table_configuration: false,
      roster_templates: {
        create: false,
        delete: false,
        read: false,
        update: false,
      },
    },
    shift_claiming_enabled: false,
    shift_swapping_enabled: false,
    labour_budget_enabled: false,
    enable_shift_acceptance: false,
    enable_roster_validation: true,
    enable_task_based_rostering: true,
    default_validation_settings: {},
    default_validation_field_settings: {
      earliest_start: 4 * 60, // 4am
      latest_finish: 23 * 60, // 11pm
      max_hours_in_week: 40,
      max_hours_worked_including_breaks: 12,
      max_hours_in_day: null,
      max_length: "11.5",
      max_shifts_in_day: 2,
      max_shifts_in_week: 6,
      min_gap: "3",
      min_length: "3",
      block_roster_publishing: false,
    },
    custom_validation_settings: {},
    deps_users_can_work_in: [],
    employee_custom_validations_map: {},
    managed_team_ids: [],
    visible_user_ids: [],
    publishable_team_ids: [],
    platform_model: {
      user: {
        fields: [],
        native: true,
        hidden: false,
        id: "1",
        name: "Staff",
        nativeType: "user",
        associations: [],
        pluralName: "Staff",
        createable: false,
        uuid: "2dc4bb99-160b-4852-8e1c-c3f5a5a399db",
      },
      schedule: {
        fields: [],
        native: true,
        hidden: false,
        id: "1",
        name: "Schedule",
        nativeType: "schedule",
        associations: [],
        pluralName: "Schedules",
        createable: false,
        uuid: "2dc4bb99-160b-4852-8e1c-c3f5a5a399da",
      },
      location: {
        fields: [],
        native: true,
        hidden: false,
        id: "1",
        name: "Location",
        nativeType: "location",
        associations: [],
        pluralName: "Locations",
        createable: false,
        uuid: "2dc4bb99-160b-4852-8e1c-c3f5a5a399dc",
      },
    },
    using_ppt_acceptance: false,
    recent_pay_period_start: moment(),
    week_start_day: 1,
  },
  history: {
    future: [],
    past: [],
  },
  settings: {
    day_range: 14,
    start_date: moment(),
    previous_start_date: null,
    finish_date: moment(),
    previous_finish_date: null,
    selected_location_ids: [-1],
    print_mode: false,
    selected_team_ids: [],
    selected_award_tags: [],
    selected_staff_types: [],
    selected_shift_details: [],
    template_id: null,
    view: "rosters",
    visible_hours: {
      finish_half_hour: 48,
      start_half_hour: 12,
    },
    user_sort_order: {},
  },
  templates: [],
  template_user_joins: [],
  loading: {
    creating_roster_pattern_shifts: false,
    deleting_shifts: false,
    loading_dates: [],
    failed_building_all_teams: false,
    teams_being_built: [],
    requests_in_progress: [],
    building_all_teams: false,
    schedule_cost_request: null,
    last_schedule_cost_request: null,
    schedules_being_recosted: [],
  },
  view_options: {
    sidebar_collapsed: false,
    active_reporting_view: "stats",
    key_stats: {
      stat_1: null,
      stat_2: null,
      stat_3: null,
    },
    day_view_graph_mode: "graph",
    highlight_validation_errors: false,
    group: "none",
    active_panel: null,
    expanded_groups: [],
    sort_staff: "first_name",
    show_teams_on_small_cards: false,
    highlight_unpublished_shifts: false,
    highlight_overtime: false,
    highlight_shifts_needing_acceptance: false,
    highlight_vacant_shifts: false,
    hide_people_with_no_shifts: false,
    collapse_multiple_cards: false,
    user_key_stat: null,
    day_key_stat: null,
    day_key_stat_two: null,
    day_key_stat_three: null,
    editing_sort_staff: false,
    only_show_time_off: false,
    custom_sort: {
      user: {},
    },
    show_staff_ratings: false,
    roster_view: "staff",
    day_view_roster_view: "stacked",
    printing_options: {
      day_by_day: false,
      simulate_portrait_a4: false,
      simulate_landscape_a4: false,
      show_reasons: true,
      show_time_off: true,
      show_time_off_day_view: true,
      show_recommended_distributions: false,
    },
    prev_schedule_sort: "start_time",
    schedule_sort: "custom",
    schedule_sort_lock: false,
  },
  schedules: [],
  published_schedules: [],
  shifts: [],
  schedule_aics: [],
  time_off: {
    unavailability: [],
    leave_requests: [],
  },
  staged: {
    staged_schedule: null,
    staged_schedules: [],
    staged_comment: null,
    staged_user_ids: [],
    staged_custom_event: null,
    staged_date: null,
    staged_leave: {
      endDate: null,
      endTime: null,
      startDate: null,
      startTime: null,
      userId: null,
    },
    staged_template_id: null,
  },
  rosters: {
    rosters: [],
    daily_schedules: [],
    user_daily_schedule_joins: [],
  },
  roster_patterns: {
    roster_pattern_schedules: [],
    roster_pattern_rdos: [],
    roster_pattern_user_joins: [],
    roster_patterns: {},
  },
  rdos: [],
  contractRDOs: [],
  sales_targets: [],
  schedule_swap_plans: [],
  weather: [],
  oncost_configurations: [],
  employment_condition_sets: [],
}

export default DEFAULT_STATE

type ConfigPermissionsType = {|
  can_approve_leave: boolean,
  can_approve_own_leave: boolean,
  can_create_leave: boolean,
  can_create_schedule: boolean,
  can_create_timesheets: boolean,
  can_import_ratios: boolean,
  can_lock_rosters: boolean,
  can_publish_rosters: boolean,
  can_update_leave: boolean,
  can_update_open_hour_periods: boolean,
  can_update_projections_table_configuration: boolean,
  can_update_schedule: boolean,
  can_update_shift_proposal: boolean,
  can_view_projections_table_configuration: boolean,
  roster_templates: Config.RosterTemplatePermissions,
|}

export type RubyStateType = {|
  archived_teams: Array<Team.Schema>,
  cognitive_creator_configurations: Array<Cognitive.CognitiveCreatorConfiguration>,
  config: {|
    business_hours: Array<Config.BusinessHours>,
    can_create_paid_meal_breaks: boolean,
    current_user_id: number,
    custom_validation_settings: Config.CustomValidationSettings,
    default_validation_field_settings: Config.DefaultValidationFieldSettings,
    default_validation_settings: Config.DefaultValidationSettings,
    deps_users_can_work_in: Array<Config.UserToDepartmentMap>,
    employee_custom_validations_map: Config.UserCustomValidationMap,
    enable_full_location_rosters: boolean,
    enable_roster_validation: boolean,
    enable_secondary_departments: boolean,
    enable_shift_acceptance: boolean,
    enable_staff_type_filter: boolean,
    enable_task_based_rostering: boolean,
    labour_budget_enabled: boolean,
    managed_team_ids: Array<number>,
    organisation: Config.OrganisationType,
    pay_period_length: number,
    permissions: ConfigPermissionsType,
    platform_model: {
      location: mixed,
      schedule: mixed,
      user: mixed,
    },
    publishable_team_ids: Array<number>,
    recent_pay_period_start: string,
    roster_week_start_day: number,
    shift_claiming_enabled: boolean,
    shift_slots: Array<Config.ShiftSlot>,
    shift_swapping_enabled: boolean,
    show_weekends: boolean,
    sms_enabled: boolean,
    using_ppt_acceptance: boolean,
    visible_user_ids: Array<number>,
  |},
  cross_department_proficiencys: Array<Cognitive.CrossDepartmentProficiency>,
  data_stream_joins: Array<DataStreamJoin.Schema>,
  data_streams: Array<DataStream.Schema>,
  department_qualifications: Array<DepartmentQualification.Schema>,
  employment_condition_sets: Array<EmploymentConditionSet.Schema>,
  enable_automatic_breaks: boolean,
  head_count_maps: Array<HeadCountMap.Schema>,
  locations: Array<Location.Schema>,
  oncost_configurations: Array<OncostConfigurations.Schema>,
  position_groups: Array<PositionGroup>,
  positions: Array<Position>,
  qualifications: Array<Qualification.Schema>,
  roster_pattern_rdos: Array<RosterPatterns.RosterPatternRDOType>,
  roster_pattern_schedules: Array<RosterPatterns.RosterPatternScheduleType>,
  roster_pattern_user_joins: Array<RosterPatterns.RosterPatternUserJoinType>,
  roster_patterns: Array<RosterPatterns.RosterPatternType>,
  rule_sets: Array<RuleSetType>,
  shift_details: Array<ShiftDetail.Schema>,
  stat_types: Array<StatType.Schema>,
  team_groups: Array<TeamGroup.Schema>,
  teams: Array<Team.Schema>,
  template_user_joins: Array<TemplateUserJoins.Schema>,
  templates: Array<Template.Schema>,
  training_logs: Array<TrainingLogs.Schema>,
  user_qualifications: Array<UserQualification.Schema>,
  users: Array<User.ShallowRubyType>,
|}

export type TransformedRubyStateType = {|
  archived_teams: Array<Team.Schema>,
  cognitive_creator_configurations: Array<Cognitive.CognitiveCreatorConfiguration>,
  config: {|
    business_hours: Array<Config.BusinessHours>,
    can_create_paid_meal_breaks: boolean,
    current_user_id: number,
    custom_validation_settings: Config.CustomValidationSettings,
    default_validation_field_settings: Config.DefaultValidationFieldSettings,
    default_validation_settings: Config.DefaultValidationSettings,
    deps_users_can_work_in: Array<Config.UserToDepartmentMap>,
    employee_custom_validations_map: Config.UserCustomValidationMap,
    enable_full_location_rosters: boolean,
    enable_roster_validation: boolean,
    enable_secondary_departments: boolean,
    enable_shift_acceptance: boolean,
    enable_staff_type_filter: boolean,
    enable_task_based_rostering: boolean,
    labour_budget_enabled: boolean,
    managed_team_ids: Array<number>,
    organisation: Config.OrganisationType,
    pay_period_length: number,
    permissions: ConfigPermissionsType,
    platform_model: {
      location: Model.Schema,
      schedule: Model.Schema,
      user: Model.Schema,
    },
    publishable_team_ids: Array<number>,
    recent_pay_period_start: moment,
    roster_week_start_day: number,
    shift_claiming_enabled: boolean,
    shift_slots: Array<Config.ShiftSlot>,
    shift_swapping_enabled: boolean,
    show_weekends: boolean,
    sms_enabled: boolean,
    using_ppt_acceptance: boolean,
    visible_user_ids: Array<number>,
  |},
  cross_department_proficiencys: Array<Cognitive.CrossDepartmentProficiency>,
  data_stream_joins: Array<DataStreamJoin.Schema>,
  data_streams: Array<DataStream.Schema>,
  department_qualifications: Array<DepartmentQualification.Schema>,
  departments: Array<Team.Schema>,
  employment_condition_sets: Array<EmploymentConditionSet.Schema>,
  enable_automatic_breaks: boolean,
  head_count_maps: Array<HeadCountMap.Schema>,
  locations: Array<Location.Schema>,
  oncost_configurations: Array<OncostConfigurations.Schema>,
  position_groups: Array<PositionGroup>,
  positions: Array<Position>,
  qualifications: Array<Qualification.Schema>,
  roster_pattern_rdos: Array<RosterPatterns.RosterPatternRDOType>,
  roster_pattern_schedules: Array<RosterPatterns.RosterPatternScheduleType>,
  roster_pattern_user_joins: Array<RosterPatterns.RosterPatternUserJoinType>,
  roster_patterns: { [roster_pattern_id: string]: RosterPatterns.RosterPatternType },
  rule_sets: Array<RuleSetType>,
  shift_details: Array<ShiftDetail.Schema>,
  stat_types: Array<StatType.Schema>,
  team_groups: Array<TeamGroup.Schema>,
  teams: Array<Team.Schema>,
  template_user_joins: Array<TemplateUserJoins.Schema>,
  templates: Array<Template.Schema>,
  training_logs: Array<TrainingLogs.Schema>,
  user_qualifications: Array<UserQualification.Schema>,
  users: Array<User.Schema>,
|}
