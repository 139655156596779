/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import { t as globalT } from "helpers/i18n"
import { sum } from "helpers/number_helpers"

import { AutoHoverBubble } from "components/Bubble"
import Button from "components/Button"
import Icon from "components/Icon"
import { NumberInput } from "components/Input"

import * as Shift from "timesheets/models/shift"

import BreaksPopover from "./BreaksPopover"

import styles from "./styles.module.scss"

const t = (key, opts) => globalT(`js.timesheets.shift_card.time_form.${key}`, opts)

export default class BreakTotal extends React.PureComponent {
  static sanitizeInput(value) {
    return value === "" ? 0 : parseFloat(value)
  }

  constructor(props) {
    super(props)

    this.handleBlur = this.handleBlur.bind(this)
    this.handleChange = this.handleChange.bind(this)

    const initialCachedValue = this.formattedBreakLength()
    this.state = {
      cachedValue: initialCachedValue,
      currentValue: initialCachedValue,
      missingBreakCache: this.missingBreakCache(), // need to cache count to prevent shiftMerge override (redux)
    }
  }

  // if shift's break changes, we need to update the cached & rendered break length to match
  UNSAFE_componentWillReceiveProps({ shift }) {
    const newLength = this.formattedBreakLength(shift)

    if (newLength !== this.state.currentValue) {
      this.setState({
        cachedValue: newLength,
        currentValue: newLength,
      })
    }
  }

  formattedBreakLength(shift = this.props.shift) {
    // if the shift doesn't exist yet, show an empty textbox rather than "0"
    if (Shift.hasMockedId(shift.get("id"))) {
      return ""
    }

    // once the shift exists, show the break length
    const breakLength = sum(shift.get(Shift.BreaksFieldName).map((b) => b.get("length")))
    return String(breakLength || 0)
  }

  isManual() {
    return this.props.shift.get(Shift.BreaksFieldName).some((b) => Shift.breakIsManual(b))
  }

  breakCount() {
    return this.props.shift.get(Shift.BreaksFieldName).filter((b) => b.get("length") > 0).size
  }

  incompleteBreakCount() {
    return this.props.shift.get(Shift.BreaksFieldName).filter((b) => !Shift.breakIsValid(b)).size
  }
  anyBreakTimes() {
    return Shift.hasAnyBreakContentToDisableEditLength(this.props.shift)
  }

  missingBreakCache() {
    return this.props.shift.get(Shift.BreaksFieldName).filter((sb) => Shift.breakHasEmptyInputs(sb)).size
  }

  missingBreakCount(cacheCount = this.state.missingBreakCache) {
    const roster_breaks = this.props.shift.get("rostered_breaks")
    if (cacheCount > 0 && roster_breaks && this.canEditBreakLength()) {
      return cacheCount
    } else {
      return 0
    }
  }

  missingRosterBreakTip(missing_break = this.props.shift.get("rostered_breaks")) {
    if (this.missingBreakCount() === 1) {
      return t("missing_breaks.one", { break: missing_break })
    } else {
      return t("missing_breaks.other", { count: this.missingBreakCount() })
    }
  }

  autoBreakCount() {
    return this.props.shift
      .get(Shift.BreaksFieldName)
      .filter((b) => b.get("length") > 0 && b.get("break_type") === Shift.ShiftBreakType.AUTOMATIC_BREAK_RULE).size
  }

  paidBreakCount() {
    return this.props.shift.get(Shift.BreaksFieldName).filter((b) => b.get("length") > 0 && b.get("paid")).size
  }

  nonAutoBreakCount() {
    return this.props.shift
      .get(Shift.BreaksFieldName)
      .filter((b) => b.get("length") > 0 && b.get("break_type") !== Shift.ShiftBreakType.AUTOMATIC_BREAK_RULE).size
  }

  shiftIsNewRecord() {
    return Shift.hasMockedId(this.props.shift.get("id"))
  }

  canEditBreakLength() {
    if (this.props.disabled || this.shiftIsNewRecord() || this.anyBreakTimes()) {
      return false
    }
    const breaks = this.breakCount()
    return breaks === 0 || breaks === 1
  }

  handleBlur(event) {
    if (!this.canEditBreakLength()) {
      return
    }

    if (this.state.currentValue !== this.state.cachedValue) {
      const shiftBreak = this.props.shift.get(Shift.BreaksFieldName).first()

      this.props.actions.shiftsSyncBreakNonTimeChange({
        shiftBreak,
        fields: {
          length: BreakTotal.sanitizeInput(event.target.value),
          break_type: Shift.ShiftBreakType.USER_ENTERED,
        },
      })
    }
  }

  handleChange(event) {
    if (!this.canEditBreakLength()) {
      return
    }

    this.setState({ currentValue: event.target.value })
  }

  renderMultipleBreakHoverType() {
    const breaksWithLength = this.props.shift.get(Shift.BreaksFieldName).filter((b) => b.get("length") > 0)
    if (breaksWithLength.size === 0) {
      return
    }

    const rows = []
    const breaksGroupedByAuto = breaksWithLength.groupBy(
      (b) => b.get("break_type") === Shift.ShiftBreakType.AUTOMATIC_BREAK_RULE
    )

    breaksGroupedByAuto.forEach((breaksByAuto, auto) => {
      breaksByAuto
        .groupBy((b) => b.get("paid"))
        .forEach((breaksByPaidAndAuto, paid) => {
          const key = ["break_time_hover_grouped", auto ? "auto" : "not_auto", paid ? "paid" : "unpaid"].join(".")
          const length = sum(breaksByPaidAndAuto.map((b) => b.get("length")))

          rows.push(t(key, { mins: `${length}${globalT("js.timesheets.duration.shorthand_minutes")}` }))
        })
    })

    return (
      <AutoHoverBubble position="right" width="large">
        {rows.map((row) => (
          <div key={row}>{row}</div>
        ))}
      </AutoHoverBubble>
    )
  }

  renderColor() {
    if (this.props.disabled) {
      return
    }

    if (this.missingBreakCount() > 0) {
      return "warning"
    }

    if (this.incompleteBreakCount() > 0) {
      return "error"
    }

    if (this.isManual()) {
      return "warning"
    }
  }

  renderTip() {
    if (this.props.disabled) {
      return
    }

    if (this.missingBreakCount() > 0) {
      return this.missingRosterBreakTip()
    }

    if (this.incompleteBreakCount() > 0) {
      return t("incomplete_break", { count: this.incompleteBreakCount() })
    }

    if (this.isManual()) {
      return t("manual_break_multi", { count: this.breakCount() })
    }
  }

  renderBreakCount() {
    const paidBreakCount = this.paidBreakCount()
    const autoBreakCount = this.autoBreakCount()
    const nonAutoBreakCount = this.nonAutoBreakCount()

    if (autoBreakCount > 0 || nonAutoBreakCount > 0 || paidBreakCount > 0) {
      // if we are showing more than one icon, we tweak the margins slightly so they still look nice centered
      // TODO: do this using flex instead
      // TODO: learn flex
      const manyIcons = [autoBreakCount > 0, nonAutoBreakCount > 0, paidBreakCount > 0].filter((b) => b).length > 1

      return (
        <span className={cn(styles.BreakCount, manyIcons ? styles.BreakCountManyIcons : null)}>
          <div className={styles.BreakTypeTotal}>
            {nonAutoBreakCount > 0 && (
              <span className={styles.breakType}>
                <Icon color="black" size="xs" type="free-breakfast" />x{nonAutoBreakCount}
              </span>
            )}
            {autoBreakCount > 0 && (
              <span className={styles.breakType}>
                <Icon color="black" size="xs" type="flash-on" />x{autoBreakCount}
              </span>
            )}
            {paidBreakCount > 0 && (
              <span className={styles.breakType}>
                <Icon color="black" size="xs" type="attach-money" />x{paidBreakCount}
              </span>
            )}
          </div>
        </span>
      )
    }
  }

  render() {
    return (
      <div className={styles.BreakTotal}>
        <div className={styles.BreakTotalInputAndCount}>
          <div className={styles.breakTotalInput}>
            <NumberInput
              color={this.renderColor()}
              disabled={!this.canEditBreakLength()}
              integerOnly
              onBlur={this.handleBlur}
              onChange={this.handleChange}
              positiveOnly
              textAlign="center"
              tipText={this.renderTip()}
              value={this.state.currentValue}
            />
          </div>

          <div className={styles.BreakTotalHover}>
            {this.renderBreakCount()}
            {this.renderMultipleBreakHoverType()}
          </div>
        </div>

        {!this.shiftIsNewRecord() && (
          <div className="my05">
            <Button
              className={styles.breakButton}
              iconLeft={this.props.disabled ? null : "edit"}
              label={t(this.props.disabled ? "show_breaks_popup" : "edit_breaks_popup", { count: this.breakCount() })}
              onClick={this.props.openPopover}
              size="sm"
              type="ghost"
            />
          </div>
        )}

        {!this.shiftIsNewRecord() && (
          <BreaksPopover
            actions={this.props.actions}
            canCreatePaidMealBreak={this.props.canCreatePaidMealBreak}
            currentUser={this.props.currentUser}
            disabled={this.props.disabled}
            hidePopover={this.props.hidePopover}
            shift={this.props.shift}
            visible={this.props.breakPopoverVisible}
          />
        )}
      </div>
    )
  }
}

BreakTotal.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  breakPopoverVisible: PropTypes.bool.isRequired,
  canCreatePaidMealBreak: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  hidePopover: PropTypes.func.isRequired,
  openPopover: PropTypes.func.isRequired,
  shift: PropTypes.object.isRequired,
}
