/**
 * @flow
 */

export const ShiftBreakType = {
  // pease keep in sync with app/models/shift_break.rb
  TIME_CLOCK: "time_clock", // break times were clocked, ie. we got the start and end times from API calls from a time clock
  USER_ENTERED: "user_entered", // break length was entered by the user on a timesheet by typing into the break length box
  AUTOMATIC_BREAK_RULE: "automatic_break_rule", // an automatic break length rule applied while the shift was clocked causing the break's length to be set
  USER_ENTERED_TIMES: "user_entered_times", // break length was entered by the user editing times manually
  NO_BREAK: "no_break", // there isn't a break
}

export const States = {
  Syncing: "SYNCING",
  Clean: "CLEAN",
  Dirty: "DIRTY",
}

export const Status = {
  Pending: "PENDING",
  Approved: "APPROVED",
}

export const MomentFields = [
  "clocked_start",
  "clocked_finish",
  "rostered_start",
  "rostered_start",
  "rostered_finish",
  "rostered_end",
  "start",
  "finish",
]

export const TimeField = {
  START: "start",
  FINISH: "finish",
}
export const TimeFields: Array<mixed> = Object.values(TimeField)

export const BreaksFieldName = "breaks"
export const BreakTimeField = {
  START: "break_start",
  FINISH: "break_finish",
}

export const FieldNames = {
  start: "start",
  break_start: "break start",
  break_finish: "break finish",
  end: "finish",
}

export const ErrorType = {
  ALT_DATE_OVERLAP: "ALT_DATE_OVEVERLAP",
  SAME_DATE_OVERLAP: "SAME_DATE_OVERLAP",
  SAME_SHIFT_OVERLAP: "SAME_SHIFT_OVERLAP",
}

export const ErrorPosition = {
  BEFORE: "BEFORE",
  AFTER: "AFTER",
}

export const DefaultValues = {
  // eslint-disable-next-line flowtype/no-weak-types
  breakdown: ([]: Array<any>),
  // eslint-disable-next-line flowtype/no-weak-types
  breaks: ([]: Array<any>),
  clocked_finish: null,
  clocked_start: null,
  department_id: null,
  finish: null,
  finish_confirmed: null,
  leave_request: null,
  // eslint-disable-next-line flowtype/no-weak-types
  photos: ([]: Array<any>),
  rostered_cost: null,
  rostered_end: null,
  rostered_length: null,
  rostered_start: null,
  awarded_cost: 0,
  auto_allowance_cost: 0,
  // eslint-disable-next-line flowtype/no-weak-types
  shift_allowances: ([]: Array<any>),
  manual_allowance_cost: 0,
  total_tips: 0,
  start: null,
  start_confirmed: null,
  state: States.Clean,
  status: Status.Pending,
  timesheet_id: null,
  tag: "",
  user_name: "",
  rating: 0,
  platform_record_id: null,
}

export const BreakDefaultValues = {
  start: null,
  finish: null,
  clocked_start: null,
  clocked_finish: null,
  start_confirmed: true,
  finish_confirmed: true,
  rostered_start: null,
  rostered_finish: null,
  paid: false,
  paid_meal_break: false,
}

/**
 * Fields that should not be updated from the result of request
 */
export const INVALID_EFFECTS = ["photos"]
